<template>
  <div
    class="d-flex align-center mt-2"
    style="gap: 20px"
  >
    <div
      class="payment__sort"
      style="width: 40px"
    >
      <div
        class="allow-drag" 
        @mousedown="mouseDown($event)"
      >
        {{ index + 1 }}
      </div>
    </div>
    <div
      style="width:120px;"
    >
      <v-text-field
        v-model="state.item_number"
        outlined
        :label="index === 0 ? 'Номер графы' : ''"
        dense
        hide-details="auto"
        background-color="white"
      />
    </div>
    <div style="width: 120px">
      <input-numeric
        v-model="state.sum"
        :label="index === 0 ? 'Сумма' : ''"
        :fraction="2"
        outlined
        dense
        hide-details="auto"
        background-color="white"
      />
    </div>
    <div style="width: 110px">
      <v-autocomplete
        v-model="state.currency_letter"
        class="mr-2"
        auto-select-first
        :items="currencies"
        :label="index === 0 ? 'Код валюты' : ''"
        item-text="letterCode"
        item-value="letterCode"
        outlined
        dense
        hide-details="auto"
        background-color="white"
        :filter="filterBySearchField"
        @change="setCurrencyCost"
      />
    </div>
    <div>
      <p class="text--disabled mb-0 ml-2">
        {{ state.currency_cost }}
      </p>
    </div>
    <div
      class="d-flex" 
      style="width: 72px"
    >
      <v-btn
        class="document-add elevation-0"
        color="transparent"
        min-width="36px"
        max-width="36px"
        @click="$emit('remove', index)"
      >
        <v-icon>mdi-delete</v-icon>
      </v-btn>
      <v-btn
        v-if="index === items.length - 1"
        class="document-add elevation-0"
        color="transparent"
        min-width="36px"
        max-width="36px"
        @click="$emit('add')"
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
import { filterBySearchFieldMixin } from "@/mixins/catalogs";
import { mapGetters } from "vuex";
import InputNumeric from "@/components/ui/input-numeric.vue";
import {fetchCurrency} from "@/helpers/math";
import {defaultPaymentItem} from "@/components/dts/goods/ware-details/payment-in-foreign-currency/payment-in-foreign-currency";

export default {
  components: { InputNumeric },
  mixins: [filterBySearchFieldMixin],
  props: {
    items: {
      required: true,
      type: Array,
    },
    item: {
      required: true,
      type: Object,
    },
    index: {
      required: true,
      type: Number,
    },
    sortIndex: {
      required: true,
      type: Number,
    },
  },
  data() {
    return {
      state: defaultPaymentItem(),      
      currencyCache: new Map(),
    };
  },
  computed: {
    ...mapGetters({
      selected: "dts/getSelected",
      selectedWares: "dts/getSelectedWares",
      currencies: "catalogs/getNsiCurrencies",
    }),
  },
  watch: {
    item: {
      handler(value) {
        this.state = value;
      },
      immediate: true,
      deep: true,
    },
    state: {
      handler(value) {
        this.state.ware_number = this.sortIndex;
        if (this.visited) this.$emit("update", { index: this.index, value });
      },
      deep: true,
    },
  },  
  methods: {
    getOriginalDeclarationDate() {
      return this.selected?.about_dt?.about_dt_json?.date_dt ?? null
    },
    getCachedCurrency({code, date}) {
      const params = {code, date}
      const key = JSON.stringify(params)
      if (this.currencyCache.has(key)) {
        return Promise.resolve(this.currencyCache.get(key))
      }
      return fetchCurrency.call(this, params).then(({rate}) => {
        this.currencyCache.set(key, rate)
        return rate
      })
    },
    setCurrencyCost() {
      this.getCachedCurrency({
        code: this.state.currency_letter,
        date: this.getOriginalDeclarationDate(),
      }).then((rate) => (this.state.currency_cost = rate));
    },    
  }
}
</script>

<style scoped>
.payment__sort {
  width: 40px !important;
  margin-right: 10px;
  flex-grow: 0;
  flex-shrink: 0;
}