<template>
  <v-dialog
    :value="show"
    width="550"
    @click:outside="confirm(false)"
  >
    <v-card>
      <v-toolbar
        color="#333"
        dark
      >
        <div class="text-h5">
          Подтвердить действие
        </div>
      </v-toolbar>
      <div class="pa-6">
        <div class="text-body-1 black--text">
          Подтвердите действие чтобы продолжить
        </div>
      </div>

      <v-divider />

      <v-card-actions>
        <v-spacer />
        <v-btn
          color="#333"
          text
          @click="confirm(false)"
        >
          Отмена
        </v-btn>
        <v-btn
          color="#5cb7b1"
          dark
          @click="confirm(true)"
        >
          Подтвердить 
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import {waitAsyncDecision} from "@/helpers/async-component";

export default {
  props:{
    show:{
      required:true,
      type:Boolean
    },      
  },
  data(){
    return{
      asyncDecision:{
        resolve:null,
        reject:null
      }
    }
  },
  methods:{
    waitAsyncDecision,
    confirm(value){
      if(value){
        if(this.asyncDecision.resolve !== null) this.asyncDecision.resolve()
      }else {
        if(this.asyncDecision.reject !== null) this.asyncDecision.reject()
      }
      this.$emit('confirm', value)
      this.close()
    },
    close(){
      this.$emit('update:show', false)
    }
  }
}
</script>
