<template>
  <v-row>
    <v-col cols="12">
      <v-row class="mt-0">   
        <v-col
          cols="12"
          class="rounded grey lighten-4 mb-4"
        >
          <v-row>
            <v-col
              cols="12"
              class="pb-0"
            >
              <h4>Основа для расчета</h4>
            </v-col>
            <v-col cols="2">
              <label>Цена в валюте</label>
              <input-numeric
                v-model="state.basis_for_calculation_json.invoice_cost"
                :fraction="2"
                :suffix="state.invoice_currency_letter"
                @change="updateBaseAndCustomsTotal"
              />
            </v-col>
            <v-col cols="2">
              <label>Цена в BYN</label>
              <input-numeric
                disabled
                :value="invoiceCostInByn"
                :fraction="2"
              />
            </v-col>
            <v-col cols="2">
              <label>Косвенные платежи</label>
              <input-numeric
                v-model="state.basis_for_calculation_json.indirect_payments"
                :fraction="2"
                :suffix="state.invoice_currency_letter"
                @change="updateBaseAndCustomsTotal"
              />
            </v-col>
            <v-col
              cols="2"
              offset="4"
            >
              <label>12 Итого</label>
              <input-numeric
                v-model="state.basis_for_calculation_json.total_12"
                :fraction="2"
                suffix="BYN"
                disabled
                background-color="grey lighten-2"
              />
            </v-col>
          </v-row>
        </v-col>
        <v-col
          cols="12"
          class="rounded grey lighten-4 mb-4"
        >
          <v-row>
            <v-col
              cols="3"
            >
              <h4>Дополнительные начисления</h4>
            </v-col>
            <v-col
              cols="5"
            >
              <div class="d-flex align-end">
                <div>
                  <label>17 Транспортные расходы до:</label>
                  <input-numeric
                    v-model="state.additional_accruals_json.cost_17"
                    :fraction="2"
                    @change="recalculateAdditionalAccrualsTotal"
                  />
                </div>
                <p class="text--disabled mb-2 ml-2">
                  {{ selected.about_transport.about_transport_json.destination_place }}
                </p>
              </div>
            </v-col>
            <v-col cols="4" />
            <v-col cols="1">
              <label>13а</label>
              <input-numeric
                v-model="state.additional_accruals_json.cost_13a"
                :fraction="2"
                @change="recalculateAdditionalAccrualsTotal"
              />
            </v-col>
            <v-col cols="1">
              <label>13б</label>
              <input-numeric
                v-model="state.additional_accruals_json.cost_13b"
                :fraction="2"
                @change="recalculateAdditionalAccrualsTotal"
              />
            </v-col>
            <v-col cols="1">
              <label>14а</label>
              <input-numeric
                v-model="state.additional_accruals_json.cost_14a"
                :fraction="2"
                @change="recalculateAdditionalAccrualsTotal"
              />
            </v-col>
            <v-col cols="1">
              <label>14б</label>
              <input-numeric
                v-model="state.additional_accruals_json.cost_14b"
                :fraction="2"
                @change="recalculateAdditionalAccrualsTotal"
              />
            </v-col>
            <v-col cols="1">
              <label>14в</label>
              <input-numeric
                v-model="state.additional_accruals_json.cost_14v"
                :fraction="2"
                @change="recalculateAdditionalAccrualsTotal"
              />
            </v-col>
            <v-col cols="1">
              <label>14г</label>
              <input-numeric
                v-model="state.additional_accruals_json.cost_14g"
                :fraction="2"
                @change="recalculateAdditionalAccrualsTotal"
              />
            </v-col>
            <v-col cols="1">
              <label>15</label>
              <input-numeric
                v-model="state.additional_accruals_json.cost_15"
                :fraction="2"
                @change="recalculateAdditionalAccrualsTotal"
              />
            </v-col>
            <v-col cols="1">
              <label>16</label>
              <input-numeric
                v-model="state.additional_accruals_json.cost_16"
                :fraction="2"
                @change="recalculateAdditionalAccrualsTotal"
              />
            </v-col>
            <v-col cols="1">
              <label>18</label>
              <input-numeric
                v-model="state.additional_accruals_json.cost_18"
                :fraction="2"
                @change="recalculateAdditionalAccrualsTotal"
              />
            </v-col>
            <v-col cols="1">
              <label>19</label>
              <input-numeric
                v-model="state.additional_accruals_json.cost_19"
                :fraction="2"
                @change="recalculateAdditionalAccrualsTotal"
              />
            </v-col>
            <v-col cols="2">
              <label>20 Итого </label>
              <input-numeric
                v-model="state.additional_accruals_json.total_20"
                :fraction="2"
                disabled
                background-color="grey lighten-2"
              />
            </v-col>
          </v-row>
        </v-col>

        <v-col
          cols="12"
          class="rounded grey lighten-4 mb-4"
        >
          <v-row>
            <v-col
              cols="12"
              class="pb-0"
            >
              <h4>Вычеты</h4>
            </v-col>
            <v-col cols="1">
              <label>21</label>
              <input-numeric
                v-model="state.deductions_json.cost_21"
                :fraction="2"
                @change="recalculateDeductionsTotal"
              />
            </v-col>
            <v-col cols="1">
              <label>22</label>
              <input-numeric
                v-model="state.deductions_json.cost_22"
                :fraction="2"
                @change="recalculateDeductionsTotal"
              />
            </v-col>
            <v-col cols="1">
              <label>23</label>
              <input-numeric
                v-model="state.deductions_json.cost_23"
                :fraction="2"
                @change="recalculateDeductionsTotal"
              />
            </v-col>
            <v-col
              offset="7"
              cols="2"
            >
              <label>24 Итого</label>
              <input-numeric
                v-model="state.deductions_json.total_24"
                :fraction="2"
                disabled
                background-color="grey lighten-2"
              />
            </v-col>
          </v-row>
        </v-col>
        <v-col
          cols="12"
          class="rounded grey lighten-4 mb-4"
        >
          <v-row>
            <v-col
              cols="12"
              class="pb-0"
            >
              <h4>Таможенная стоимость ввозимых товаров</h4>
            </v-col>
            <v-col
              cols="2"
              offset="8"
            >
              <label>12+20-24</label>
              <input-numeric
                v-model="state.customs_cost_json.total_25b"
                :fraction="2"
                suffix="USD"
                disabled
                background-color="grey lighten-2"
              />
            </v-col>
            <v-col
              cols="2"
            >
              <label>12+20-24</label>
              <input-numeric
                v-model="state.customs_cost_json.total_25a"
                :fraction="2"
                suffix="BYN"
                disabled
                background-color="grey lighten-2"
              />
            </v-col>
          </v-row>
        </v-col>
        <v-col
          cols="12"
          class="rounded grey lighten-4 mb-4"
        >
          <v-row>
            <payment-in-foreign-currency-list
              :sort-index="sortIndex"
              :items="state.payment_in_foreign_currency_json"
            />
          </v-row>
        </v-col>
        <v-col
          cols="12"
          class="rounded grey lighten-4"
        >
          <v-row>
            <additional-information-list
              :items="state.additional_information_json"
            />
          </v-row>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>
<script>
import { mapGetters } from "vuex";
import InputNumeric from "@/components/ui/input-numeric.vue";
import { fetchCurrency } from "@/helpers/math";
import PaymentInForeignCurrencyList from "@/components/dts/goods/ware-details/payment-in-foreign-currency/payment-in-foreign-currency-list.vue";
import AdditionalInformationList from "@/components/dts/goods/ware-details/additional-information/additional-information-list.vue";

export default {
  components: {
    InputNumeric,
    PaymentInForeignCurrencyList, AdditionalInformationList
  },
  props: {
    sortIndex: {
      required: true,
      type: Number,
    },
    ware: {
      required: true,
      type: Object,
    }
  },  
  data() {
    return {
      promises: [],
      currencyCache: new Map(),
      invoiceCostInByn: null,
      state: "",
    }
  },
  computed: {
    ...mapGetters({
      selected: "dts/getSelected",
      selectedWares: "dts/getSelectedWares",
    }),
  },
  watch: {
    ware: {
      handler(value) {
        this.state = value;
      },
      immediate: true,
      deep: true,
    },
    state: {
      handler() {
        this.$emit("update");
      },
      deep: true,
    },
  },
  methods: {
    getOriginalDeclarationDate() {
      return this.selected?.about_dt?.about_dt_json?.date_dt ?? null
    },
    updateBaseAndCustomsTotal() {      
      this.recalculateBaseTotal()
        .then(this.recalculateCustomsCosts)
    },
    recalculateBaseTotal() {
      const promise = this.recalculateInvoiceCostInBYN()
        .then(this.getIndirectPaymentsInByn)
        .then((indirectPaymentInBYN) => {
          const total = (+this.invoiceCostInByn + +indirectPaymentInBYN).toFixed(2)
          this.state.basis_for_calculation_json.total_12 = total
          this.state.basis_for_calculation_json.invoice_cost_byn = this.invoiceCostInByn
          return total
        })
      this.promises.push(promise)      
      return promise
    },
    getIndirectPaymentsInByn() {
      const value = this.state.basis_for_calculation_json.indirect_payments
      if (!value) return 0
      const promise = new Promise((resolve, reject) => {
        const code = this.state.invoice_currency_letter;
        const date = this.getOriginalDeclarationDate()
        if (!code || !date) return reject("getIndirectPaymentsInByn: Отсутствуют обязательные параметры")
        resolve({code, date})
      }).then(this.getCachedCurrency)
        .then((rate) => {
          return (+value * rate).toFixed(2)
        })
      this.promises.push(promise)
      return promise
    },
    recalculateInvoiceCostInBYN() {
      const value = this.state.basis_for_calculation_json.invoice_cost
      const promise = new Promise((resolve, reject) => {
        const code = this.state.invoice_currency_letter;
        const date = this.getOriginalDeclarationDate()
        if (!code || !date) return reject("recalculateInvoiceCostInBYN: Отсутствуют обязательные параметры")
        resolve({code, date})
      }).then(this.getCachedCurrency)
        .then((rate) => {
          this.invoiceCostInByn = value ? (+value * rate).toFixed(2) : 0
          return this.invoiceCostInByn
        }).catch((err) => {
          this.$error("Не удалось рассчитать инвойс в BYN")
          throw err
        })
      this.promises.push(promise)
      return promise
    },
    recalculateAdditionalAccrualsTotal() {
      this.state.additional_accruals_json.total_20 = Object.entries(this.state.additional_accruals_json)
        .reduce((previousValue, [key, value]) => {
          return key.startsWith("total") ? previousValue : previousValue + +value ?? 0
        }, 0).toFixed(2)
      this.recalculateCustomsCosts()
      this.recalculateShippingCost()
    },
    recalculateDeductionsTotal() {
      this.state.deductions_json.total_24 = Object.entries(this.state.deductions_json)
        .reduce((previousValue, [key, value]) => {
          return key.startsWith("total") ? previousValue : previousValue + +value ?? 0
        }, 0).toFixed(2)
      this.recalculateCustomsCosts()
    },
    recalculateCustomsCosts() {
      this.recalculateCustomsCostInBYN()
      this.recalculateCustomsCostInUSD()
    },
    getCachedCurrency({code, date}) {
      const params = {code, date}
      const key = JSON.stringify(params)
      if (this.currencyCache.has(key)) {
        return Promise.resolve(this.currencyCache.get(key))
      }
      return fetchCurrency.call(this, params).then(({rate}) => {
        this.currencyCache.set(key, rate)
        return rate
      })
    },
    recalculateCustomsCostInUSD() {
      const promise = this.getCachedCurrency({
        code: "USD",
        date: this.getOriginalDeclarationDate(),
      }).then((rate) => (+this.state.customs_cost_json.total_25a / rate).toFixed(2))
        .then(cost => this.state.customs_cost_json.total_25b = cost)
      this.promises.push(promise)
      return promise
    },
    recalculateCustomsCostInBYN() {
      this.state.customs_cost_json.total_25a =
          (+this.state.basis_for_calculation_json.total_12 +
              +this.state.additional_accruals_json.total_20 -
              +this.state.deductions_json.total_24).toFixed(2)
    },
    recalculateShippingCost() {
      this.getCachedCurrency({
        code: "USD",
        date: this.getOriginalDeclarationDate(),
      }).then((rate) => (+this.state.additional_accruals_json.total_20 / rate).toFixed(2))
        .then(cost => this.shippingCost = cost)
    }, 
  }
}
</script>
<style scoped>
.bordered {
  background: #fff;
  border: 1px #6E6E6E solid;
  border-radius: 6px;
  margin-right: auto;
  column-gap: 20px;
}

.textarea-border-solid {
  border: 1px solid rgba(0, 0, 0, 0.8);
  border-radius: 4px;
  padding: 0px 50px 10px 10px
}
</style>
