<template>
  <v-overlay
    :value="show"
  >
    <v-card
      ref="card"
      light
      width="100vw"
      height="100vh"
      class="overflow-y-auto"
    >
      <v-card-title class="flex justify-space-between teal white--text">
        Товары списком
        <v-btn
          min-width="40px"
          width="40px"
          dark
          class="elevation-0 red"
          @click="close"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-container class="relative">
        <ware-compact
          :id="id"
          ref="wareCompact"
          :sort-index="sortIndex"
          @navigate="navigate"
          @close="close"
        >
          <template #navigation>
            <ware-navigation
              :sort-index.sync="sortIndex"
              @beforeChange="updateWare"
              @afterChange="resetWareTriggers"
            />
          </template>
        </ware-compact>
      </v-container>
    </v-card>
  </v-overlay>
</template>
<script>
import WareCompact from "@/components/dts/goods/ware-details/ware-compact.vue";
import WareNavigation from "@/components/dts/goods/ware-details/ware-navigation.vue";
import {mapGetters} from "vuex";
import {disablePageScroll} from "@/events/common";
import {eventBus} from "@/bus"
import closeOnEscapeMixin from "@/mixins/close-on-escape.mixin";

export default {
  components: { WareNavigation, WareCompact},
  mixins:[closeOnEscapeMixin],
  props: {
    show: {
      required: true,
      type: Boolean
    },
    wareId: {
      required: true,
      type: Number
    },
    wareSortIndex: {
      required: true,
      type: Number
    }
  },
  data() {
    return {
      sortIndex: null,
      id: null,
    }
  },
  computed: {
    ...mapGetters({
      selectedWares: 'dts/getSelectedWares'
    }),
  },
  watch: {
    sortIndex() {
      this.updateId()
    },
    show: {
      handler(value) {
        if (value) {
          this.sortIndex = this.wareSortIndex
          this.id = this.wareId
        }
        disablePageScroll.trigger(value)
      },
      immediate: true
    }
  },
  methods: {
    updateId() {
      this.id = this.getWareIdBySortIndex()
    },
    getWareIdBySortIndex() {
      const ware = this.selectedWares.find(ware => ware.sort_index === this.sortIndex)
      return ware.id
    },
    updateWare() {
      this.$refs.wareCompact.uploadIfUpdated()
    },
    navigate(sortIndex) {
      this.sortIndex = sortIndex
      this.updateId()
    },
    resetWareTriggers() {
      this.$refs.wareCompact.resetTriggers()
    },
    close() {
      this.$emit('update:show', false)
      eventBus.$emit("updateWareDetails", {items: this.items, wareId: this.id});
    }
  }
}
</script>

<style scoped>
.relative{
  position: relative;
}
</style>
