<template>
  <v-col
    cols="12"
    :class="classOptions"
  >
    <v-tabs
      id="document-goods-tabs"
      :value="value"
      @input="(v) => $emit('input', v)"
    >
      <v-tab-item
        v-for="tab in tabs"
        :key="tab.title"
      >
        <component
          :is="tab.component"
          :code="code"
        />
      </v-tab-item>
    </v-tabs>
  </v-col>
</template>
<script>
export default {
  props:{
    value:{
      validator:(v) => typeof v === 'number' || v === null,
      required: true
    },
    code:{
      required:true,
      validator:(v) => typeof v === 'string' || v === null
    },
    tabs: {
      required: true,
      type: Array
    },
    bordered:{
      required: false,
      type: Boolean,
      default:false
    }
  },
  computed:{
    classOptions(){
      return this.$attrs.class
    }
  }
}
</script>