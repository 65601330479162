<template>
  <div>
    <v-row>
      <v-col cols="12">
        <div class="rounded grey lighten-4 pa-4">
          <v-row>
            <v-col
              cols="12"
              class="pb-0"
            >
              <div class="font-weight-bold d-flex align-center mt-2">
                Дополнительные данные
              </div>
            </v-col>
            <v-col
              v-if="!hasInformation"
              cols="12"
              class="text-center"
            >
              <v-btn
                class="document-add elevation-0 px-15"
                color="transparent"
                @click="addInformation"
              >
                Добавить
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </v-col>
            <v-col
              v-if="hasInformation"
              cols="12"
            >
              <additional-information-item
                v-for="(item, index) in items"
                :key="index"
                :item="item"
                :items="items"
                :index="index"
                @add="addInformation"
                @update="updateInformation"
                @remove="removeInformation"
              />
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import AdditionalInformationItem from "./additional-information-item.vue";
import { defaultInformationItem } from "@/components/dts/goods/ware-details/additional-information/additional-information";

export default {
  components: {
    AdditionalInformationItem,
  },
  props: {
    items: {
      required: true,
      type: Array,
    },
  },
  computed: {
    ...mapGetters({
      selectedWares: "dts/getSelectedWares",
    }),
    hasInformation() {
      return !!this.items.length;
    },
  },
  methods: {
    addInformation() {
      this.items.push(defaultInformationItem());      
    },
    updateInformation({ index, value }) {
      this.items.splice(index, 1, value);
    },
    removeInformation(index) {
      this.items.splice(index, 1);
    },   
  },
};
</script>
