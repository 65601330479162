<template>
  <v-row
    :id="block_id"
    v-click-outside="triggerOnFocus"
  >
    <v-col cols="12">
      <v-row>
        <v-col
          class="pt-4 pr-0 mr-4 d-flex align-center align-self-start col-2"
        >
          <slot name="navigation" />
          <v-menu offset-y>
            <template #activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
              >
                mdi-tune
              </v-icon>
            </template>
            <v-list>
              <!-- <v-list-item
                :disabled="disabledView"
                @click="createWare"
              >
                Добавить товар
              </v-list-item> -->
              <v-list-item
                :disabled="disabledView"
                @click="confirmRemoveWare"
              >
                Удалить товар
              </v-list-item>
              <!-- <v-list-item
                :disabled="disabledView"
              >
                Копировать товар
              </v-list-item> -->
            </v-list>
          </v-menu>
        </v-col>
        <v-col
          cols="2"
          class="d-flex align-center align-self-start pt-4"
        >
          <v-text-field
            v-model="item.tn_ved"
            v-mask="'##########'"
            :disabled="disabledView"
            class="mr-2"
            outlined
            dense
            hide-details
            background-color="white"
            @keypress.enter="showTNVED"
            @change="tnvedChanged"
          />
          <v-btn
            small
            :class="{ 'teal lighten-2': tab === 0 }"
            max-width="30px"
            min-width="30px"
            text
            @click="toggleTreeView"
          >
            <v-icon> mdi-file-tree-outline </v-icon>
          </v-btn>
          <v-btn
            small
            max-width="30px"
            min-width="30px"
            text
            @click="showCodeInfo(item.tn_ved)"
          >
            <v-icon>mdi-information</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-row class="mt-0">
        <v-col
          cols="2"
          class="d-flex align-center align-self-start pt-4 pr-0 mr-4"
        >
          <v-row>
            <v-col>
              <label>
                Номер товара из ДТ
                <v-text-field
                  v-model="item.ware_dt_details_json.ware_number_from_dt"
                  outlined
                  dense
                  hide-details="auto"
                  background-color="grey lighten-2"
                  disabled
                />
              </label>
            </v-col>
          </v-row>
        </v-col>
        <v-col
          cols="2"
          class="d-flex align-center align-self-start pt-4 pl-4"
        >
          <v-row>
            <v-col>
              <label>
                Метод
                <v-row class="pt-3 pl-2">
                  <div style="width: 80px">
                    <input-numeric
                      v-if="selected.declaration_type == 'DTS1'"
                      v-model="item.valuation_method_code"
                      hide-details
                      outlined
                      dense
                      background-color="grey lighten-2"
                      disabled
                    />
                    <v-combobox
                      v-else
                      v-model="item.valuation_method_code"
                      :items="methods"
                      outlined
                      dense
                      hide-details
                      auto-select-first
                      background-color="white"
                      @change="methodChange"
                    />
                  </div>
                  <div
                    v-if="selected.declaration_type == 'DTS2'"
                    class="pt-2 pl-1"
                    style="width: 32px"
                  >
                    <v-icon> mdi-arrow-right </v-icon>
                  </div>
                  <div
                    v-if="selected.declaration_type == 'DTS2'"
                    style="width: 80px"
                  >
                    <v-combobox
                      v-if="item.valuation_method_code == '6'"
                      v-model="item.base_valuation_method_code"
                      :items="base_methods"
                      outlined
                      dense
                      hide-details
                      auto-select-first
                      background-color="white"
                      @change="methodChange"
                    />
                    <input-numeric
                      v-else
                      v-model="item.base_valuation_method_code"
                      outlined
                      dense
                      hide-details
                      background-color="grey lighten-2"
                      disabled
                    />
                  </div>
                </v-row>
              </label>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <ware-compact-1
          v-if="getMethod() == 1"
          :sort-index="sortIndex"
          :ware="item"
          @update="readyToUpdate"
        />
        <ware-compact-23
          v-if="getMethod() == 23"
          :sort-index="sortIndex"
          :ware="item"
          @update="readyToUpdate"
        />
        <ware-compact-4
          v-if="getMethod() == 4"
          :sort-index="sortIndex"
          :ware="item"
          @update="readyToUpdate"
        />
        <ware-compact-5
          v-if="getMethod() == 5"
          :sort-index="sortIndex"
          :ware="item"
          @update="readyToUpdate"
        />
      </v-row>
    </v-col>
    <component
      :is="activeComponent"
      :ref="getReferenceAsync"
      :show.sync="show"
    />
  </v-row>
</template>
<script>
import { mapGetters } from "vuex";
import { filterBySearchFieldMixin } from "@/mixins/catalogs";
import blockAutoUpdateMixin from "@/mixins/block-auto-update.mixin";
import { showCatalog } from "@/events/statistics";
import { eventBus } from "@/bus";
import TnvedTooltip from "@/components/dt/goods/tnved-tooltip.vue";
import { showCodeInfo } from "@/helpers/tnved";
import WareDynamicTnvedTabs from "@/components/documents/ware/ware-dynamic-tnved-tabs.vue";
import ConfirmAction from "@/components/shared/confirm-action.vue";
import {
  getReferenceAsync,
  waitAsyncDecision,
  waitResultOrDie,
} from "@/helpers/async-component";
import InputNumeric from "@/components/ui/input-numeric.vue";
import cloneDeep from "lodash.clonedeep";
import WareCompact1 from "@/components/dts/goods/ware-details/ware-compact-1.vue";
import WareCompact23 from "@/components/dts/goods/ware-details/ware-compact-23.vue";
import WareCompact4 from "@/components/dts/goods/ware-details/ware-compact-4.vue";
import WareCompact5 from "@/components/dts/goods/ware-details/ware-compact-5.vue";
import axios from "axios";
import { DTS } from "@/http-routes";

const WD_TNVED = "ware-detail-good-item-tnved";
export default {
  components: {
    InputNumeric,
    WareDynamicTnvedTabs,
    TnvedTooltip,
    WareCompact1,
    WareCompact23,
    WareCompact4,
    WareCompact5,
  },
  mixins: [filterBySearchFieldMixin, blockAutoUpdateMixin],
  props: {
    id: {
      required: true,
      type: Number,
    },
    sortIndex: {
      required: true,
      type: Number,
    },
  },
  data() {
    return {
      block_id: "ware-details-modal",
      activeComponent: null,
      show: false,
      tab: null,
      tabs: [
        {
          title: "Показать структуру",
          id: 1,
          component: () => import("@/components/tnved/structure.vue"),
        },
      ],
      latestCode: null,
      promises: [],
      is_goods_item: false,
      item: {
        id: null,
        declaration_id: null,
        tn_ved: null,
        sort_index: null,
        valuation_method_code: 0,
        base_valuation_method_code: 0,
        ware_dt_details_json: {
          ware_number_from_dt: 0,
        },
        invoice_currency_letter: null,
        basis_for_calculation_json: {
          invoice_cost: null,
          indirect_payments: null,
          total_12: null,
          cost_11: null,
          measure_11_unit_digit: null,
        },
        payment_in_foreign_currency_json: [],
        additional_accruals_json: {
          cost_13a: null,
          cost_13b: null,
          cost_14a: null,
          cost_14b: null,
          cost_14v: null,
          cost_14g: null,
          cost_15: null,
          cost_16: null,
          cost_17: null,
          cost_18: null,
          cost_19: null,
          total_20: null,
        },
        deductions_json: {
          cost_21: null,
          cost_22: null,
          cost_23: null,
          total_24: null,
          cost_12: null,
          cost_13: null,
          cost_14: null,
          cost_15: null,
          total_16: null,
        },
        customs_cost_json: {
          total_25a: null,
          total_25b: null,
        },
        quantity_of_goods_json: {
          quantity_identical_goods: null,
          measure_identical_goods_unit_digit: null,
          measure_identical_goods_unit_letter: null,
          quantity_goods: null,
          measure_goods_unit_digit: null,
          measure_goods_unit_letter: null,
        },
        adjustments_to_transaction_value_json: {
          cost_12a: null,
          cost_12b: null,
          cost_12v: null,
          cost_12g: null,
          cost_12d: null,
          total_13: null,
          cost_14a: null,
          cost_14b: null,
          cost_14v: null,
          cost_14g: null,
          cost_14d: null,
          total_15: null,
          total_16: null,
        },
        expenses_json: {
          cost_11: null,
          cost_12a: null,
          cost_12b: null,
          cost_12v: null,
          cost_12g: null,
          cost_12d: null,
          cost_12e: null,
          cost_12j: null,
          cost_13: null,
          cost_14: null,
          cost_15: null,
          cost_16: null,
        },
        additional_information_json: []
      },
      methods: ["2", "3", "4", "5", "6"],
      base_methods: ["1", "2", "3", "4", "5"],
    };
  },
  computed: {
    ...mapGetters({
      selected: "dts/getSelected",
      selectedWares: "dts/getSelectedWares",
      requests: "dts/getRequests",
      disabledView: "dts/getVisibility",
    }),
  },
  watch: {
    show(value) {
      if (value === false) this.activeComponent = null;
    },
    selectedWares: {
      handler() {
        if (this.selectedWares.length) this.setFields();
      },
      deep: true,
    },
    sortIndex: {
      handler() {
        this.setFields();
      },
      immediate: true,
    },
  },
  created() {
    eventBus.$on(WD_TNVED, this.onTnvedCodeSelect);
  },
  mounted() {
    this.setEventListeners();
  },
  beforeDestroy() {
    eventBus.$off(WD_TNVED, this.onTnvedCodeSelect);
  },
  methods: {
    getReferenceAsync,
    waitAsyncDecision,
    waitResultOrDie,
    createWare() {
      Promise.all(this.requests)
        .then(() => {
          return this.$store.dispatch("dts/addNewGoodsItem");
        })
        .then(() => this.$success("Добавлен товар"))
        .catch(() => this.$error());
    },
    showConfirm() {
      this.activeComponent = ConfirmAction;
      this.show = true;
      return this.waitAsyncDecision();
    },
    confirmRemoveWare() {
      this.showConfirm()
        .then(this.waitResultOrDie)
        .then(this.removeWare)
        .then(this.navigateToFirst)
        .catch(() => {});
    },
    removeWare() {
      return this.$store
        .dispatch("dts/deleteGoodsItem", {
          id: this.id,
        })
        .then(() => this.$info("Товар удален"))
        .catch((error) => {
          this.$error("Ошибка удаления товара");
          throw error;
        });
    },
    navigateToFirst() {
      this.selectedWares.length
        ? this.$emit("navigate", 1)
        : this.$emit("close");
    },
    toggleTreeView() {
      this.tab = this.tab === 0 ? null : 0;
    },
    showCodeInfo,
    onAddTnvedCodeSelect({ code }) {
      this.item.add_tn_ved = code;
      this.readyToUpdate();
    },
    tnvedChanged() {
      this.updateLatestCode();
    },
    onTnvedCodeSelect({ code }) {
      this.item.tn_ved = code;
      this.readyToUpdate();
      this.updateLatestCode();
    },
    readyToUpdate() {
      this.fields_been_visited = true;
      this.hasChanges = true;
    },
    uploadIfUpdated() {
      if (this.hasChanges && this.fields_been_visited) {
        return this.uploadData()
          .then(() => this.$success("Обновлено"))
          .then(() => this.resetTriggers())
          .catch(() => this.$error());
      }
    },
    getCurrentWare() {
      return cloneDeep(
        this.selectedWares.find((ware) => ware.sort_index === this.sortIndex) ??
          {}
      );
    },
    setFields() {
      const ware = this.getCurrentWare();
      Object.keys(this.item).forEach((key) => {
        if (key in ware) {
          this.item[key] = ware[key];
        }
      });

      this.updateLatestCode();
    },
    updateLatestCode() {
      this.latestCode = this.item.tn_ved;
    },
    getCompleteWare() {
      const item = cloneDeep(this.item);
      return item;
    },
    uploadData() {
      const payload = cloneDeep(this.item);
      return Promise.all(this.promises).then(() =>
        this.$store.dispatch("dts/updateGoodsItem", payload)
      );
    },
    showTNVED() {
      showCatalog.trigger({
        type: "tnved",
        id: this.id,
        search: this.item.tn_ved,
        zIndex: 300,
        customEventName: WD_TNVED,
      });
    },
    getMethod() {
      const ware = this.selectedWares.find(
        (ware) => ware.sort_index === this.sortIndex
      );
      this.generalMethod =
        ware.valuation_method_code == "1" ||
        ware.base_valuation_method_code == "1"
          ? 1
          : ware.valuation_method_code == "2" ||
            ware.valuation_method_code == "3" ||
            ware.base_valuation_method_code == "2" ||
            ware.base_valuation_method_code == "3"
            ? 23
            : ware.valuation_method_code == "4" ||
              ware.base_valuation_method_code == "4"
              ? 4
              : 5;
      return this.generalMethod;
    },
    getOriginalDeclarationDate() {
      return this.selected?.about_dt?.about_dt_json?.date_dt ?? null;
    },
    methodChange() {
      if (this.item.valuation_method_code != "6") {
        this.item.base_valuation_method_code = null;
        axios
          .post(
            `${DTS}ware/setWareDataAccordingMethod?valuationMethodCode=${this.item.valuation_method_code}`,
            {
              id: this.item.id,
            }
          )
          .then((res) => {
            Object.keys(this.item).forEach((key) => {
              if (key in res.data) {
                this.item[key] = res.data[key];
              }
            });
          });
      } else if (this.item.base_valuation_method_code != null) {
        axios
          .post(
            `${DTS}ware/setWareDataAccordingMethod?valuationMethodCode=${this.item.valuation_method_code}&baseValuationMethodCode=${this.item.base_valuation_method_code}`,
            {
              id: this.item.id,
            }
          )
          .then((res) => {
            Object.keys(this.item).forEach((key) => {
              if (key in res.data) {
                this.item[key] = res.data[key];
              }
            });
          });
      }
    },
  },
};
</script>
<style scoped>
.bordered {
  background: #fff;
  border: 1px #6e6e6e solid;
  border-radius: 6px;
  margin-right: auto;
  column-gap: 20px;
}

.textarea-border-solid {
  border: 1px solid rgba(0, 0, 0, 0.8);
  border-radius: 4px;
  padding: 0px 50px 10px 10px;
}
</style>
