<template>
  <v-row>
    <v-col cols="12">
      <v-row class="mt-0">
        <v-col
          class="rounded grey lighten-4 mb-4"
        >
          <v-row>
            <v-col
              cols="12"
              class="pb-0"
            >
              <h4>Основа для расчета</h4>
            </v-col>
            <v-col cols="12">
              <label>Цена сделки с идентичными (однородными) товарами</label>
              <input-numeric
                v-model="state.basis_for_calculation_json.cost_11"
                :fraction="2"
                @change="recalculateAdjustmentsToTransactionTotal16"
              />
            </v-col>
          </v-row>
        </v-col>        
        <v-col          
          class="rounded grey lighten-4 mb-4 ml-4"
        >
          <v-row>
            <v-col
              cols="12"
              class="pb-0"
            >
              <h4>Количество</h4>
            </v-col>
            <v-col cols="4">
              <label>17а</label>
              <div class="d-flex mb-2">
                <input-numeric
                  ref="add_measure_unit_quantity"
                  v-model="state.quantity_of_goods_json.quantity_identical_goods"
                  :fraction="2"
                  class="mr-1"
                  outlined
                  dense
                  hide-details="auto"
                  background-color="white"
                  @change="recalculateCustomsCosts"
                />
                <v-autocomplete
                  ref="add_measure_unit_digit"
                  v-model="state.quantity_of_goods_json.measure_identical_goods_unit_digit"
                  auto-select-first
                  item-text="shortDescription"
                  item-value="code"
                  :items="measureUnits"
                  outlined
                  dense
                  hide-details="auto"
                  background-color="white"
                  @change="getMeasureUnitLetter"
                />
              </div>
            </v-col>
            <v-col cols="4">
              <label>17б</label>
              <div class="d-flex mb-2">
                <input-numeric
                  ref="add_measure_unit_quantity"
                  v-model="state.quantity_of_goods_json.quantity_goods"
                  :fraction="2"
                  class="mr-1"
                  outlined
                  dense
                  hide-details="auto"
                  background-color="white"
                  @change="recalculateCustomsCosts"
                />
                <v-autocomplete
                  ref="add_measure_unit_digit"
                  v-model="state.quantity_of_goods_json.measure_goods_unit_digit"
                  auto-select-first
                  item-text="shortDescription"
                  item-value="code"
                  :items="measureUnits"
                  outlined
                  dense
                  hide-details="auto"
                  background-color="white"
                  @change="getMeasureUnitLetter"
                />
              </div>
            </v-col>
          </v-row>
        </v-col>
        <v-col
          cols="12"
          class="rounded grey lighten-4 mb-4"
        >
          <v-row>
            <v-col
              cols="12"
              class="pb-0"
            >
              <h4>Поправки к стоимости сделки</h4>
            </v-col>
            <v-col cols="1">
              <label>12а</label>
              <input-numeric
                v-model="state.adjustments_to_transaction_value_json.cost_12a"
                :fraction="2"
                @change="recalculateAdjustmentsToTransactionTotal13"
              />
            </v-col>
            <v-col cols="1">
              <label>12б</label>
              <input-numeric
                v-model="state.adjustments_to_transaction_value_json.cost_12b"
                :fraction="2"
                @change="recalculateAdjustmentsToTransactionTotal13"
              />
            </v-col>
            <v-col cols="1">
              <div>
                <label>12в</label>
                <input-numeric
                  v-model="state.adjustments_to_transaction_value_json.cost_12v"
                  :fraction="2"
                  @change="recalculateAdjustmentsToTransactionTotal13"
                />
              </div>
            </v-col>
            <v-col
              cols="3"
              class="pt-11"
            >
              <p class="text--disabled mb-2 ml-2">
                {{
                  selected.about_transport.about_transport_json
                    .destination_place
                }}
              </p>
            </v-col>
            <v-col cols="1">
              <label>12г</label>
              <input-numeric
                v-model="state.adjustments_to_transaction_value_json.cost_12g"
                :fraction="2"
                @change="recalculateAdjustmentsToTransactionTotal13"
              />
            </v-col>
            <v-col cols="1">
              <label>12д</label>
              <input-numeric
                v-model="state.adjustments_to_transaction_value_json.cost_12d"
                :fraction="2"
                @change="recalculateAdjustmentsToTransactionTotal13"
              />
            </v-col>
            <v-col cols="2">
              <label>13 Итого по (12)</label>
              <input-numeric
                v-model="state.adjustments_to_transaction_value_json.total_13"
                :fraction="2"
                disabled
                suffix="BYN"
                background-color="grey lighten-2"
                @change="recalculateAdjustmentsToTransactionTotal16"
              />
            </v-col>
            <v-col cols="2" />
            <v-col cols="1">
              <label>14а</label>
              <input-numeric
                v-model="state.adjustments_to_transaction_value_json.cost_14a"
                :fraction="2"
                @change="recalculateAdjustmentsToTransactionTotal15"
              />
            </v-col>
            <v-col cols="1">
              <label>14б</label>
              <input-numeric
                v-model="state.adjustments_to_transaction_value_json.cost_14b"
                :fraction="2"
                @change="recalculateAdjustmentsToTransactionTotal15"
              />
            </v-col>
            <v-col cols="1">
              <div>
                <label>14в</label>
                <input-numeric
                  v-model="state.adjustments_to_transaction_value_json.cost_14v"
                  :fraction="2"
                  @change="recalculateAdjustmentsToTransactionTotal15"
                />
              </div>
            </v-col>
            <v-col
              cols="3"
              class="pt-11"
            >
              <p class="text--disabled mb-2 ml-2">
                {{
                  selected.about_transport.about_transport_json
                    .destination_place
                }}
              </p>
            </v-col>
            <v-col cols="1">
              <label>14г</label>
              <input-numeric
                v-model="state.adjustments_to_transaction_value_json.cost_14g"
                :fraction="2"
                @change="recalculateAdjustmentsToTransactionTotal15"
              />
            </v-col>
            <v-col cols="1">
              <label>14д</label>
              <input-numeric
                v-model="state.adjustments_to_transaction_value_json.cost_14d"
                :fraction="2"
                @change="recalculateAdjustmentsToTransactionTotal15"
              />
            </v-col>
            <v-col cols="2">
              <label>15 Итого по (14)</label>
              <input-numeric
                v-model="state.adjustments_to_transaction_value_json.total_15"
                :fraction="2"
                disabled
                suffix="BYN"
                background-color="grey lighten-2"
                @change="recalculateAdjustmentsToTransactionTotal16"
              />
            </v-col>

            <v-col cols="2">
              <label>16 Итого (11-13+15)</label>
              <input-numeric
                v-model="state.adjustments_to_transaction_value_json.total_16"
                :fraction="2"
                disabled
                suffix="BYN"
                background-color="grey lighten-2"
              />
            </v-col>
            <v-col cols="1" />
          </v-row>
        </v-col>        
        <v-col
          cols="12"
          class="rounded grey lighten-4 mb-4"
        >
          <v-row>
            <v-col
              cols="12"
              class="pb-0"
            >
              <h4>Таможенная стоимость ввозимых товаров</h4>
            </v-col>
            <v-col
              cols="2"
              offset="8"
            >
              <label>((16x17б/17а)или(16))</label>
              <input-numeric
                v-model="state.customs_cost_json.total_25b"
                :fraction="2"
                suffix="USD"
                disabled
                background-color="grey lighten-2"
              />
            </v-col>
            <v-col
              cols="2"
            >
              <label>((16x17б/17а)или(16))</label>
              <input-numeric
                v-model="state.customs_cost_json.total_25a"
                :fraction="2"
                suffix="BYN"
                disabled
                background-color="grey lighten-2"
              />
            </v-col>
          </v-row>
        </v-col>
        <v-col
          cols="12"
          class="rounded grey lighten-4 mb-4"
        >
          <v-row>
            <payment-in-foreign-currency-list
              :sort-index="sortIndex"
              :items="state.payment_in_foreign_currency_json"
            />
          </v-row>
        </v-col>
        <v-col
          cols="12"
          class="rounded grey lighten-4"
        >
          <v-row>
            <additional-information-list
              :items="state.additional_information_json"
            />
          </v-row>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>
<script>
import { mapGetters } from "vuex";
import InputNumeric from "@/components/ui/input-numeric.vue";
import { fetchCurrency } from "@/helpers/math";
import PaymentInForeignCurrencyList from "@/components/dts/goods/ware-details/payment-in-foreign-currency/payment-in-foreign-currency-list.vue";
import {getMeasureUnitShortName} from '@/helpers/catalogs'
import AdditionalInformationList from "@/components/dts/goods/ware-details/additional-information/additional-information-list.vue";

export default {
  components: {
    InputNumeric,
    PaymentInForeignCurrencyList, AdditionalInformationList,
  },
  props: {
    sortIndex: {
      required: true,
      type: Number,
    },
    ware: {
      required: true,
      type: Object,
    }
  },  
  data() {
    return {
      promises: [],
      currencyCache: new Map(),
      state: "",
    }
  },
  computed: {
    ...mapGetters({
      selected: "dts/getSelected",
      selectedWares: "dts/getSelectedWares",
      measureUnits: "catalogs/getNsiMeasureUnits",
    }),
  },
  watch: {
    ware: {
      handler(value) {
        this.state = value;
      },
      immediate: true,
      deep: true,
    },
    state: {
      handler() {
        this.$emit("update");
      },
      deep: true,
    },
  },
  methods: {
    getOriginalDeclarationDate() {
      return this.selected?.about_dt?.about_dt_json?.date_dt ?? null;
    },
    recalculateAdjustmentsToTransactionTotal13() {
      this.state.adjustments_to_transaction_value_json.total_13 = (
        +this.state.adjustments_to_transaction_value_json.cost_12a +
        +this.state.adjustments_to_transaction_value_json.cost_12b +
        +this.state.adjustments_to_transaction_value_json.cost_12v +
        +this.state.adjustments_to_transaction_value_json.cost_12g +
        +this.state.adjustments_to_transaction_value_json.cost_12d
      ).toFixed(2);
      this.recalculateAdjustmentsToTransactionTotal16();
    },
    recalculateAdjustmentsToTransactionTotal15() {
      this.state.adjustments_to_transaction_value_json.total_15 = (
        +this.state.adjustments_to_transaction_value_json.cost_14a +
        +this.state.adjustments_to_transaction_value_json.cost_14b +
        +this.state.adjustments_to_transaction_value_json.cost_14v +
        +this.state.adjustments_to_transaction_value_json.cost_14g +
        +this.state.adjustments_to_transaction_value_json.cost_14d
      ).toFixed(2);
      this.recalculateAdjustmentsToTransactionTotal16();
    },
    recalculateAdjustmentsToTransactionTotal16() {
      this.state.adjustments_to_transaction_value_json.total_16 = (
        +this.state.basis_for_calculation_json.cost_11 -
        +this.state.adjustments_to_transaction_value_json.total_13 +
        +this.state.adjustments_to_transaction_value_json.total_15
      ).toFixed(2);
      this.recalculateCustomsCosts();
    },
    recalculateCustomsCosts() {
      this.recalculateCustomsCostInBYN();
      this.recalculateCustomsCostInUSD();
    },
    getCachedCurrency({ code, date }) {
      const params = { code, date };
      const key = JSON.stringify(params);
      if (this.currencyCache.has(key)) {
        return Promise.resolve(this.currencyCache.get(key));
      }
      return fetchCurrency.call(this, params).then(({ rate }) => {
        this.currencyCache.set(key, rate);
        return rate;
      });
    },
    recalculateCustomsCostInUSD() {
      const promise = this.getCachedCurrency({
        code: "USD",
        date: this.getOriginalDeclarationDate(),
      })
        .then((rate) =>
          (+this.state.customs_cost_json.total_25a / rate).toFixed(2)
        )
        .then((cost) => (this.state.customs_cost_json.total_25b = cost));
      this.promises.push(promise);
      return promise;
    },
    recalculateCustomsCostInBYN() {
      this.state.customs_cost_json.total_25a =
        this.state.quantity_of_goods_json.quantity_identical_goods > 0
          ? ((this.state.adjustments_to_transaction_value_json.total_16 *
              this.state.quantity_of_goods_json.quantity_goods) /
              this.state.quantity_of_goods_json.quantity_identical_goods).toFixed(2)
          : (this.state.adjustments_to_transaction_value_json.total_16).toFixed(2);
    }, 
    getMeasureUnitLetter() {
      this.state.quantity_of_goods_json.measure_identical_goods_unit_letter = getMeasureUnitShortName(this.measureUnits, this.state.quantity_of_goods_json.measure_identical_goods_unit_digit)
      this.state.quantity_of_goods_json.measure_goods_unit_letter = getMeasureUnitShortName(this.measureUnits, this.state.quantity_of_goods_json.measure_goods_unit_digit)
    } 
  },
};
</script>
<style scoped>
.bordered {
  background: #fff;
  border: 1px #6e6e6e solid;
  border-radius: 6px;
  margin-right: auto;
  column-gap: 20px;
}

.textarea-border-solid {
  border: 1px solid rgba(0, 0, 0, 0.8);
  border-radius: 4px;
  padding: 0px 50px 10px 10px;
}
</style>
