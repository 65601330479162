<template>
  <div
    class="d-flex align-center mt-2"
    style="gap: 20px"
  >
    <div
      class="information__sort"
      style="width: 40px"
    >
      <div
        class="allow-drag" 
        @mousedown="mouseDown($event)"
      >
        {{ index + 1 }}
      </div>
    </div>
    <div
      style="width:120px;"
    >
      <v-combobox
        v-model="state.code"
        :items="['1','2','3','9']"
        :label="index === 0 ? 'Код' : ''"
        outlined
        dense
        hide-details="auto"
        background-color="white"
      />
    </div>
    <div style="width: 650px">
      <v-text-field
        v-model="state.description"
        :label="index === 0 ? 'Дополнительные данные' : ''"
        :fraction="2"
        outlined
        dense
        hide-details="auto"
        background-color="white"
      />
    </div>
    <div
      v-show="state.code == '3'"
      style="width: 300px"
    >
      <v-text-field
        v-model="state.customs_doc_reg_num"
        :fraction="2"
        outlined
        dense
        hide-details="auto"
        background-color="white"
        :label="'Регистрационный номер ДТ'"
      />
    </div>
    <div
      class="d-flex" 
      style="width: 72px"
    >
      <v-btn
        class="document-add elevation-0"
        color="transparent"
        min-width="36px"
        max-width="36px"
        @click="$emit('remove', index)"
      >
        <v-icon>mdi-delete</v-icon>
      </v-btn>
      <v-btn
        v-if="index === items.length - 1"
        class="document-add elevation-0"
        color="transparent"
        min-width="36px"
        max-width="36px"
        @click="$emit('add')"
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>

import { mapGetters } from "vuex";
import {defaultInformationItem} from "@/components/dts/goods/ware-details/additional-information/additional-information";

export default {
  props: {
    items: {
      required: true,
      type: Array,
    },
    item: {
      required: true,
      type: Object,
    },
    index: {
      required: true,
      type: Number,
    },
  },
  data() {
    return {
      state: defaultInformationItem(),
    };
  },
  computed: {
    ...mapGetters({
      selected: "dts/getSelected",
      selectedWares: "dts/getSelectedWares",
    }),
  },
  watch: {
    item: {
      handler(value) {
        this.state = value;
      },
      immediate: true,
      deep: true,
    },
    state: {
      handler(value) {
        if (this.visited) this.$emit("update", { index: this.index, value });
      },
      deep: true,
    },
  },  
}
</script>

<style scoped>
.information__sort {
  width: 40px !important;
  margin-right: 10px;
  flex-grow: 0;
  flex-shrink: 0;
}