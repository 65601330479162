import { render, staticRenderFns } from "./ware-compact-5.vue?vue&type=template&id=5620ef93&scoped=true"
import script from "./ware-compact-5.vue?vue&type=script&lang=js"
export * from "./ware-compact-5.vue?vue&type=script&lang=js"
import style0 from "./ware-compact-5.vue?vue&type=style&index=0&id=5620ef93&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5620ef93",
  null
  
)

export default component.exports