<template>
  <v-row>
    <v-col cols="12">
      <v-row class="mt-0">
        <v-col
          cols="12"
          class="rounded grey lighten-4 mb-4"
        >
          <v-row>
            <v-col
              cols="12"
            >
              <h4>Расходы на изготовление или приобретение материалов и расходы на производство</h4>
            </v-col>
            <v-col cols="1">
              <label>12а</label>
              <input-numeric
                v-model="state.expenses_json.cost_12a"
                :fraction="2"
                @change="recalculateExpensesTotal11"
              />
            </v-col>
            <v-col cols="1">
              <label>12b</label>
              <input-numeric
                v-model="state.expenses_json.cost_12b"
                :fraction="2"
                @change="recalculateExpensesTotal11"
              />
            </v-col>
            <v-col cols="1">
              <label>12v</label>
              <input-numeric
                v-model="state.expenses_json.cost_12v"
                :fraction="2"
                @change="recalculateExpensesTotal11"
              />
            </v-col>
            <v-col cols="1">
              <label>12g</label>
              <input-numeric
                v-model="state.expenses_json.cost_12g"
                :fraction="2"
                @change="recalculateExpensesTotal11"
              />
            </v-col>
            <v-col cols="1">
              <label>12d</label>
              <input-numeric
                v-model="state.expenses_json.cost_12d"
                :fraction="2"
                @change="recalculateExpensesTotal11"
              />
            </v-col>
            <v-col cols="1">
              <label>12e</label>
              <input-numeric
                v-model="state.expenses_json.cost_12e"
                :fraction="2"
                @change="recalculateExpensesTotal11"
              />
            </v-col>
            <v-col cols="1">
              <label>12j</label>
              <input-numeric
                v-model="state.expenses_json.cost_12j"
                :fraction="2"
                @change="recalculateExpensesTotal11"
              />
            </v-col>
            <v-col
              offset="3"
              cols="2"
            >
              <label>11 </label>
              <input-numeric
                v-model="state.expenses_json.cost_11"
                :fraction="2"
                disabled                
                suffix="BYN"
                background-color="grey lighten-2"
              />
            </v-col>

            <v-col cols="1">
              <label>13</label>
              <input-numeric
                v-model="state.expenses_json.cost_13"
                :fraction="2"
                @change="recalculateCustomsCosts"
              />
            </v-col>
            <v-col cols="1">
              <div>
                <label>14</label>
                <input-numeric
                  v-model="state.expenses_json.cost_14"
                  :fraction="2"
                  @change="recalculateCustomsCosts"
                />
              </div>
            </v-col>
            <v-col
              cols="3"
              class="pt-11"
            >
              <p class="text--disabled mb-2 ml-2">
                {{
                  selected.about_transport.about_transport_json
                    .destination_place
                }}
              </p>
            </v-col> 
            <v-col cols="1">
              <label>15</label>
              <input-numeric
                v-model="state.expenses_json.cost_15"
                :fraction="2"
                @change="recalculateCustomsCosts"
              />
            </v-col> 
            <v-col cols="1">
              <label>16</label>
              <input-numeric
                v-model="state.expenses_json.cost_16"
                :fraction="2"
                @change="recalculateCustomsCosts"
              />
            </v-col> 
          </v-row>
        </v-col>
        <v-col
          cols="12"
          class="rounded grey lighten-4 mb-4"
        >
          <v-row>
            <v-col
              cols="12"
              class="pb-0"
            >
              <h4>Таможенная стоимость ввозимых товаров</h4>
            </v-col>
            <v-col
              cols="2"
              offset="8"
            >
              <label>Итого по 11,13-16</label>
              <input-numeric
                v-model="state.customs_cost_json.total_25b"
                :fraction="2"
                suffix="USD"
                disabled
                background-color="grey lighten-2"
              />
            </v-col>
            <v-col
              cols="2"
            >
              <label>Итого по 11,13-16</label>
              <input-numeric
                v-model="state.customs_cost_json.total_25a"
                :fraction="2"
                suffix="BYN"
                disabled
                background-color="grey lighten-2"
              />
            </v-col>
          </v-row>
        </v-col>
        <v-col
          cols="12"
          class="rounded grey lighten-4 mb-4"
        >
          <v-row>
            <payment-in-foreign-currency-list
              :sort-index="sortIndex"
              :items="state.payment_in_foreign_currency_json"
            />
          </v-row>
        </v-col>
        <v-col
          cols="12"
          class="rounded grey lighten-4"
        >
          <v-row>
            <additional-information-list
              :items="state.additional_information_json"
            />
          </v-row>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>
<script>
import { mapGetters } from "vuex";
import InputNumeric from "@/components/ui/input-numeric.vue";
import { fetchCurrency } from "@/helpers/math";
import PaymentInForeignCurrencyList from "@/components/dts/goods/ware-details/payment-in-foreign-currency/payment-in-foreign-currency-list.vue";
import AdditionalInformationList from "@/components/dts/goods/ware-details/additional-information/additional-information-list.vue";

export default {
  components: {
    InputNumeric,
    PaymentInForeignCurrencyList, AdditionalInformationList
  },
  props: {
    sortIndex: {
      required: true,
      type: Number,
    },
    ware: {
      required: true,
      type: Object,
    }
  },  
  data() {
    return {
      promises: [],
      currencyCache: new Map(),
      state: "",
    }
  },
  computed: {
    ...mapGetters({
      selected: "dts/getSelected",
      selectedWares: "dts/getSelectedWares",
    }),
  },
  watch: {
    ware: {
      handler(value) {
        this.state = value;
      },
      immediate: true,
      deep: true,
    },
    state: {
      handler() {
        this.$emit("update");
      },
      deep: true,
    },
  },
  methods: {
    getOriginalDeclarationDate() {
      return this.selected?.about_dt?.about_dt_json?.date_dt ?? null
    },   
    recalculateExpensesTotal11() {
      this.state.expenses_json.cost_11 =
          (+this.state.expenses_json.cost_12a +
              +this.state.expenses_json.cost_12b +
              +this.state.expenses_json.cost_12v +
              +this.state.expenses_json.cost_12g +
              +this.state.expenses_json.cost_12d +
              +this.state.expenses_json.cost_12e +
              +this.state.expenses_json.cost_12j).toFixed(2);
      this.recalculateCustomsCosts()
    },    
    recalculateCustomsCosts() {
      this.recalculateCustomsCostInBYN()
      this.recalculateCustomsCostInUSD()
    },
    getCachedCurrency({code, date}) {
      const params = {code, date}
      const key = JSON.stringify(params)
      if (this.currencyCache.has(key)) {
        return Promise.resolve(this.currencyCache.get(key))
      }
      return fetchCurrency.call(this, params).then(({rate}) => {
        this.currencyCache.set(key, rate)
        return rate
      })
    },
    recalculateCustomsCostInUSD() {
      const promise = this.getCachedCurrency({
        code: "USD",
        date: this.getOriginalDeclarationDate(),
      }).then((rate) => (+this.state.customs_cost_json.total_25a / rate).toFixed(2))
        .then(cost => this.state.customs_cost_json.total_25b = cost)
      this.promises.push(promise)
      return promise
    },
    recalculateCustomsCostInBYN() {
      this.state.customs_cost_json.total_25a =
          (+this.state.expenses_json.cost_11 +
              +this.state.expenses_json.cost_13 +
              +this.state.expenses_json.cost_14 +
              +this.state.expenses_json.cost_15 +
              +this.state.expenses_json.cost_16).toFixed(2)
    },
  }
}
</script>
<style scoped>
.bordered {
  background: #fff;
  border: 1px #6E6E6E solid;
  border-radius: 6px;
  margin-right: auto;
  column-gap: 20px;
}

.textarea-border-solid {
  border: 1px solid rgba(0, 0, 0, 0.8);
  border-radius: 4px;
  padding: 0px 50px 10px 10px
}
</style>
