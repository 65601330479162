<template>
  <v-menu
    offset-y
    :close-on-content-click="true"
    max-width="750"
  >
    <template #activator="{ on, attrs }">
      <v-btn
        :disabled="isDisabled"
        v-bind="attrs"
        class="ml-2"
        text
        max-width="36px"
        min-width="36px"
        v-on="on"
        @click="getData"
      >
        <v-icon>mdi-information</v-icon>
      </v-btn>
    </template>

    <v-simple-table
      v-if="items.length"
      class="pt-2"
    >
      <tbody>
        <tr
          v-for="(element, idx) in items"
          :key="'status-item-'+idx"
        >
          <td><b>{{ element.fullCode }}</b></td>
          <td>{{ element.fullName }}</td>    
          <td>
            <v-icon
              v-if="element.including !== null"
              class="mx-2"
              :color="element.including === '1' ? 'green': 'red'"
            >
              {{ element.including === '1'? 'mdi-check': 'mdi-cancel' }}
            </v-icon>
          </td>
        </tr>
      </tbody>
    </v-simple-table>
  </v-menu>
</template>
<script>
import { normalize } from "@/helpers/catalogs";
import moment from "moment/moment";
export default {
  props:{
    item:{
      required:true,
      type:Object
    }
  },
  data:() => ({
    items:[]
  }),
  computed:{
    isDisabled(){
      return !this.item.tn_ved || this.item?.add_tn_ved?.length < 4
    }
  },
  methods:{
    getActionDate(){
      const format = 'DD.MM.YYYY'
      const date = this.item.shipment_date
      return moment(date, 'YYYY-MM-DD').isValid() ? moment(date).format(format) : moment().format(format)
    },
    getData(){
      if (!this.isDisabled) {
        const actionDate = this.getActionDate()
        this.$store.dispatch("catalogs/getExtraTnved", {code:this.item.tn_ved, actionDate}).then(res => {
          this.items = this.toFlatArray(normalize({data:res.data, withIncluding:true})).filter(i => i.fullCode === this.item.add_tn_ved)
        }).catch(() => {
          this.$snackbar({text:'Возникла ошибка при загрузке справочника', color:'red', top:false, right: false})
        });
      }
    },
    toFlatArray(data){
      const result = []
      if(Array.isArray(data)){
        data.forEach(i => result.push(...this.toFlatArray(i)))
      }else if(typeof data === 'object' && data !== null && !Array.isArray(data)){
        Object.keys(data).forEach(key => {
          if(Array.isArray(data[key])){
            data[key].forEach(i => result.push(...this.toFlatArray(i)))
          }    
        })
        result.push(data)
      }
      return result 
    }
    
  }
}
</script>