<template>
  <div class="d-flex">
    <v-autocomplete
      :value="sortIndex"
      :items="sortIndexes"
      style="width: 90px;"
      dense
      outlined
      class="mr-2"
      item-text="title"
      item-value="sort_index"
      hide-details
      background-color="white"
      @change="onChange"
    >
      <template #item="{ item }">
        <strong class="mr-4">{{ item }}. </strong>
        {{ `${selectedWares[item - 1].tn_ved ?? ''} ${selectedWares[item - 1].description ?? ''} ${selectedWares[item - 1].origin_country_letter ?? ''}` }}
      </template>
    </v-autocomplete>
    
    <v-btn
      min-width="40"
      width="40"
      text
      :disabled="sortIndex === 1"
      class="prev-arr"
      @click="prev"
    >
      <v-icon>
        mdi-arrow-left
      </v-icon>
    </v-btn>
    <v-btn
      min-width="40"
      width="40"
      class="next-arr"
      text
      :disabled="!nextExist"
      @click="next"
    >
      <v-icon>
        mdi-arrow-right
      </v-icon>
    </v-btn>
  </div>
</template>
<script>
import {mapGetters} from "vuex";

export default {
  props:{
    sortIndex:{
      type:Number,
      required:true
    }
  },
  computed:{
    ...mapGetters({
      selectedWares:"dts/getSelectedWares"
    }),
    sortIndexes(){
      return this.selectedWares.map(ware => ware.sort_index) ?? []
    },
    nextExist(){
      const nextSortIndex = this.sortIndex + 1
      return !!this.selectedWares.find(ware => ware.sort_index === nextSortIndex)
    },
  },
  created() {
    this.bindNavigation()
  },
  beforeDestroy() {
    this.unbindNavigation()
  },
  methods:{
    bindNavigation(){
      document.addEventListener("keyup", this.navigateToWare)
    },
    unbindNavigation(){
      document.removeEventListener("keyup", this.navigateToWare)
    },
    navigateToWare(event){
      if(["TEXTAREA", "INPUT"].includes(event.target.nodeName)) return;
      if(event.code === "ArrowRight" && this.nextExist){
        this.next()
      }else if(event.code === "ArrowLeft" && this.sortIndex !== 1){
        this.prev()
      }
    },
    onChange(sortIndex){
      if(!this.sortIndexes.includes(sortIndex)){
        return this.$error()
      }
      this.$emit('beforeChange')
      this.$emit('update:sortIndex', sortIndex)
      this.$emit('afterChange')
    },
    prev(){
      this.$emit('beforeChange')
      this.$emit('update:sortIndex', this.sortIndex - 1)
      this.$emit('afterChange')
    },
    next(){
      this.$emit('beforeChange')
      this.$emit('update:sortIndex', this.sortIndex + 1)
      this.$emit('afterChange')
    }
  }
}
</script>

<style scoped>
.prev-arr{
  top: 50%;
  left: 50px;
  position: fixed;
}
.next-arr{
  top: 50%;
  right:50px;
  position: fixed;
}
</style>